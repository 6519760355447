.company {
  --about-us-row-title-width: 130px;
  --about-us-title-width: 173px;
  --about-us-subtitle-width: 87px;
  --company-title-width: 173px;
  --company-subtitle-width: 67px;
  --leadership-title-width: 205px;
  --leadership-subtitle-width: 70px;
  --global-office-title-width: 264px;
  --office-title-height: 52px;
}

.company .whiteTitle-subtitle {
  font-family: 'Arial';
  font-size: 20px;
  font-weight: bold;
}

.company h1 {
  margin-top: 10px;
  margin-bottom: 28px;
}

.company video {
  height: var(--page-video-height);
  /* margin-top: 55px; */
  object-fit: cover;
}

.company-aboutUsAndLeadership {
  background-attachment: fixed;
  background-image: url('../../images/company_about_us_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.company-officesHeadline {
  margin-top: 12px;
}

.company-content {
  --company-content-padding-top-down: 40px;
  --company-content-padding-side: 110px;

  padding: var(--company-content-padding-top-down) var(--company-content-padding-side);
  width: 800px !important;
  font-size: 18px;
}

.company-subtitle {
  font-size: var(--ml-font-size);
  line-height: 30px;
  width: var(--company-subtitle-width);
}

.company-dividing-line {
  border: 1px solid #989898;
  margin: 20px auto;
}

.company-aboutUsRow {
  background-color: white;
  border-top: 1px solid var(--border-color);
  display: flex;
}
.company-aboutUsRow:first-child {
  border-top: 1px solid white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.company-aboutUsRow:last-of-type {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.company-aboutUsRow p {
  margin: 0;
}

.company-rowWithLink {
  flex-direction: column;
}
.company-rowWithLink {
  padding: 0 var(--spacing-size-e) var(--spacing-size-e) 0;
}

.company-aboutUsRowContent {
  display: grid;
  grid-template-columns: 0fr 1fr;
  font-size: var(--ml-font-size);
  letter-spacing: 0.02em;
  line-height: 180%;
  padding: var(--spacing-size-d) 32px;

  @media screen and (max-width: 576px) {
    grid-template-columns: none;
  }

}

.company-aboutUsRowTitle {
  display: flex;
  font-weight: bold;
  padding-right: var(--spacing-size-d);
  min-width: 150px;
}

.company-aboutUsLargeContent {
  padding-top: var(--spacing-size-d);
}

.company-leadership {
  margin-top: var(--about-us-padding-top-down);
}

.company-leadershipCards {
  display: grid;
  gap: var(--spacing-size-d);
  grid-template-columns: repeat(3, 1fr);
}



.company-globalOffices.content-padding {
  margin-bottom: 0px;
  padding-top: 110px;
  padding-bottom: 110px;
}
.company-globalOffices .company-subtitle {
  width: 53px;
}

.company-globalOfficesTitle {
  display: flex;
  font-size: var(--m-font-size);
  gap: 100px;
}

.company-globalOfficesCards {
  display: flex;
  font-family: var(--secondary-font);
  gap: var(--spacing-5);
  justify-content: space-between;
}

.company-globalOfficesCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}
.company-globalOfficesCard img {
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-radius: 8px;
}

.company-officeCardTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 0;
  height: 80px;
  display: flex;
  align-items: center;
}

.company-officeCardContent {
  font-family: 'Arial';
  font-size: 14px;
  font-weight: 400;
  line-height: 180%;
  margin-bottom: 0;
  letter-spacing: 0.7px;
}

.company-globalOfficesTitleWrapper {
  display: flex;
  gap: 100px;
  justify-content: space-between;
}

.services-fbLinkArrow {
  margin-left: 20px;
}

@media only screen and (max-width: 1200px) {
  .company-leadershipCards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 576px) {
  .company-content {
    width: 100% !important;
  }

  .company-dividing-line {
    display: none;
  }

  .company-aboutUs {
    padding-bottom: 40px !important;
  }

  .company-globalOfficesTitleWrapper {
    flex-direction: column;
    gap: 0;
  }

  .company-globalOfficesTitle, .company-globalOfficesCards {
    flex-direction: column;
  }

  .company-leadershipCards {
    grid-template-columns: repeat(1, 1fr);
  }

  .company-globalOfficesTitle {
    gap: 0;
  }

  .company-globalOfficesCards {
    margin-top: 20px;
  }
  .company-globalOfficesCard img {
    width: 100%;
  }

  .company .whiteTitle-subtitle {
    font-size: 12px;
  }

  .company-aboutUsRowContent {
    // grid-template-columns: none;
    text-align: left;
  }

  .company-aboutUsRowContent.last-content {
    grid-template-columns: none;
    gap: 8px;
    margin-bottom: 16px;
  }

  .company .title-wrapper {
    text-align: center;
  }

  .company .title-with-bg-wrapper {
    display: inline-grid;
    text-align: center;
    margin: 0 auto 30px auto;
    padding: 16px;
  }

  .company .title-with-bg {
    padding: 12px 0;
    font-size: 42px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.84px;
  }
  .company-aboutUsRowTitle {
    min-width: none;
  }

  .company h1 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: 700;
  }

  .company-globalOffices.content-padding{
    padding: 40px 0;
  }
}

@media only screen and (max-width: 991.98px) {
  .company-globalOfficesCards {
    flex-wrap: wrap;
  }
  .company-globalOfficesCard {
    flex: 0 0 100%
    img{
      height: 232px;
    }
  }

}

@media only screen and (max-width: 390px) {
  .company video {
    margin-top: 0;
  }
  
}
