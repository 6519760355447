.careers-step {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  margin: 5px;
  border-radius: 6px;

  .tag {
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    font-size: 18px;
  }
}

.careers-stepWrapper {
  display: flex;
  gap: 10px;
}

.careers-currentStep {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: white;
}

.careers-recruitingSteps {
  display: grid;
  gap: 10px;
  justify-content: center;
  margin: 16px 0;

  img {
    width: unset !important;
  }
}

.careers-recruitingStep {
  background: white;
  border: 1px solid #989898;
  color: var(--primary-color);
  transition: all .5s ease-out;
}