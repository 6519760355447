.swiper-backface-hidden .swiper-slide {
  transform: initial;
}

.swiper-wrapper {
  height: 380px;
}

[data-aos="goin"] {
  opacity: 0;
  transform: translateY(100px);
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
    transform: translateX(0);
  }
}

.careers {

  #careersPc {
    display: inline-block;
    width: 100%;
  }

  #careersMobile {
    display: none;
    background-color: #F9F9FA;
  }

  #careersTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    aspect-ratio: 1440 / 608;
    margin-top: 90px;
    padding: 0 82px;
    background-image: url('../../images/Careers/careers_title_image.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    div:first-child {
      width: 100%;
      height: 100%;
      max-width: 610px;
      max-height: 187px;
      background-image: url('../../images/Careers/careers_title.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    div:last-child {
      width: 100%;
      height: 100%;
      max-width: 637px;
      max-height: 74px;
      background-image: url('../../images/Careers/careers_title_2.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .careers-divider {
    width: 100%;
    height: 1px;
    background-color: #99B0D1;
  }

  .careers-container {
    display: flex;
    flex-direction: column;
    padding: 50px 110px  0 110px;
    width: 100%;

    &-title {
      display: flex;
      gap: 20px;
      margin: 50px 0;
      font-family: 'Oswald', sans-serif;
      font-size: 48px;
      font-weight: 700;
      line-height: 22px;
      color: #204377;
      align-items: baseline;

      &-sub-title {
        font-size: 14px;
        color: #000000;
      }
    }

    &-our-team {
      display: flex;
      gap: 10px;
      width: 100%;
      max-width: 1440px;
      padding: 0 40px;
      margin-bottom: 20px;
      align-self: center;

      &-img {
        flex-grow: 1;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &-welfare-benefits {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 20px;
      width: 100%;
      max-width: 1280px;
      padding: 0 80px;
      align-self: center;

      img {
        aspect-ratio: 230 / 250;
      }
    }

    &-communication {
      display: grid;
      grid-template-columns: 7fr 5fr;
      align-items: center;
      margin-top: 30px;
      gap: 140px;

      p {
        max-width: 800px;
        font-size: 14px;
        line-height: 230%;
      }

      img {
        max-width: 458px !important;
        height: auto;
      }
    }

    img {
      width: 100%;
      max-width: 1980px;
      justify-self: center;
    }

    #pickUpPc {
      display: flex;
      flex-direction: column;
      gap: 27px;

      .pick-up-card {
        display: flex;
        justify-content: space-between;
        gap: 80px;
        padding: 0 10px;

        .pick-up-content {
          display: flex;
          flex-direction: column;
          gap: 17px;
          width: 100%;
          max-width: 700px;
          overflow: hidden;
          text-overflow: ellipsis; 
          white-space: nowrap;

          .pick-up-title {
            color: black;
            text-decoration: none;
            font-size: 20px;
            font-weight: bold;
            line-height: 180%;
            transition: all 160ms ease-out;

            &:hover {
              text-decoration: underline;
              text-decoration-style: solid;
              text-decoration-skip-ink: none;
              text-decoration-thickness: auto;
              text-underline-offset: auto;
              text-underline-position: from-font;
            }
          }

          p {
            margin: 0;
            font-size: 14px;
            line-height: 230%;
          }
          
          .pick-up-wantedly {
            width: 166px;
            height: 38px;
            align-self: end;
            background-image: url('../../images/Careers/wantedly.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            transition: all 160ms ease-out;

            &:hover {
              background-image: url('../../images/Careers/wantedly_hover.png');
            }
          }
        }

        .pick-up-img {
          position: relative;
          width: 100%;
          max-width: 378px;
          //margin: 0 70px;
          transition: opacity 160ms ease-out;
          
          img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
            object-fit: contain;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    #gallery-area {
      display: flex;
      flex-direction: column;
      gap: 27px;

      .gallery-items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 80px;
        //align-self: start;

        img {
          width: 100%;
          height: 100%;
          max-width: 450px;
          //max-height: 220px;
        }
      }
    }

    &-recruiting-philosophy {
      display: flex;
      justify-content: center;
      padding: 0 70px;

      & p:first-of-type {
        width: 100%;
        max-width: 450px;
        font-size: 32px;
        line-height: 120%;
        font-weight: bold;
      }

      & p:last-of-type {
        width: 100%;
        max-width: 609px;
        font-size: 14px;
        line-height: 230%;
      }
    }
  }

  .careers-contactUs {
    display: flex;
    gap: 60px;
    margin: 0 0 150px 0;
    text-align: center;
    justify-content: center;
  }

  .accordion-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 342px;
    padding: 20px 0;
    justify-self: center;
    font-size: 16px;
    line-height: 30px;
    transition: all 500ms;

    &-title {
      padding-left: 50px;
      font-weight: bold;
    }

    .accordion-item {
      display: flex;
      //gap: 16px;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      margin-left: 60px;
      
      &-title {
        display: flex;
        gap: 16px;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        padding: 10px 32px;
        border-bottom: #aaaaaa 1px solid;
        cursor: pointer;
        font-size: 14px;

          &:hover {
            text-decoration: underline;
            text-decoration-style: solid;
            text-decoration-skip-ink: none;
            text-decoration-thickness: auto;
            text-underline-offset: auto;
            text-underline-position: from-font;
          }


          &-text {
            margin: 0;
            font-size: 14px;
          }

        img {
          max-width: 16px;
          transition: transform 500ms;
        }
      }

      &-content {
        display: flex;
        width: 100%;
        max-width: 1200px;
        height: 0;
        padding-left: 32px;
        padding-right: 60px;
        overflow: hidden;
        opacity: 0;
        transition: all 500ms;

          &-text {
            height: fit-content;
            margin:  16px 0 0 0;
            font-weight: 300;
            font-size: 14px;
            //padding-left: 16px;
          }
        }
      }
    }
  }

@media(max-width: 1024px) {
  .careers {
    .careers-container {
      padding: 50px 80px 0px 80px;

      #pickUpPc {
  
        .pick-up-card {
          gap: 80px;

          .pick-up-img {
            img {
              min-width: 200px;
            }
          }
        }
      }

      #gallery-area {
        .gallery-items {
          gap: 40px;
        }
      }

      .accordion-block {
        &-title {
          padding-left: 8px;
        }

        .accordion-item {
          margin-left: 8px;
        }
      }
    }
  }
}

@media(max-width: 768px) {
  .careers {
    .careers-container {
      padding: 50px 60px 0 60px;

      #pickUpPc {
  
        .pick-up-card {
          gap: 60px;

          .pick-up-img {
            img {
              min-width: 180px;
            }
          }
        }
      }


      &-communication {
        gap: 60px;
      }

      #gallery-area {
        .gallery-items {
          gap: 20px;
        }
      }
    }

    .careers-contactUs {
      gap: 8px;
    }
  }
}

@media(max-width: 576px){
  .careers {

    #careersPc {
      display: none;
    }
  
    #careersMobile {
      display: flex;
      flex-direction: column;

      #careersTitleMobile {
        display: flex;
        flex-direction: column;
        gap: 60px;
        align-items: center;
        width: 100%;
        margin-top: 90px;
        padding-top: 64px;
        padding-bottom: 30px;
        padding-left: 40px;
        padding-right: 40px;
        background-image: url('../../images/Careers/careers_title_image.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    
        div:first-child {
          flex: 332;
          width: 100%;
          height: 100%;
          aspect-ratio: 332 / 123;
          background-image: url('../../images/Careers/careers_title_mobile.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
    
        div:last-child {
          flex: 318;
          width: 100%;
          height: 100%;
          aspect-ratio: 318 / 63;
          background-image: url('../../images/Careers/careers_title_2_mobile.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .mobile-title {
        display: grid;
        gap: 20px;
        font-family: 'Oswald', sans-serif;
        font-size: 38px;
        font-weight: 700;
        line-height: 120%;
        color: #204377;
        align-items: baseline;

        &-sub {
          font-size: 12px;
          font-weight: bold;
          line-height: 120%;
          color: #000000;
        }
      }

      .mobile-our-team-container {
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 40px 20px;

        &-our-team {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-bottom: 20px;

          &-content {
            display: flex;
            gap: 10px;
            width: 100%;

            &-img {
              flex-grow: 1;
              width: 100%;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
        }

        &-welfare-benefits {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 10px;

          img {
            width: 100%;
            aspect-ratio: 170 / 250;
          }
        }
      }

      .mobile-communication-container {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding-top: 80px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: white;

        &-communication {
          display: flex;
          flex-direction: column;
          gap: 20px;

          p {
            margin: 0;
            font-size: 12px;
            line-height: 120%;
            letter-spacing: 2%;
          }

          img {
            padding: 10px;
          }
        }

        #pickUpMobile {
          display: flex;
          flex-direction: column;
          gap: 27px;

          .pick-up-card {
            display: flex;
            flex-direction: column;
            gap: 15px;

            &-main-img {
              img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
                object-fit: contain;
              }
            }
      
            &-content {
              display: flex;
              flex-direction: column;
              gap: 17px;
    
              &-title {
                color: black;
                text-decoration: none;
                font-size: 16px;
                font-weight: bold;
                line-height: 180%;
                transition: all 160ms ease-out;
    
                &:hover {
                  text-decoration: underline;
                  text-decoration-style: solid;
                  text-decoration-skip-ink: none;
                  text-decoration-thickness: auto;
                  text-underline-offset: auto;
                  text-underline-position: from-font;
                }
              }
    
              p {
                margin: 0;
                font-size: 14px;
                font-weight: 300;
                line-height: 230%;
              }
              
              &-wantedly {
                width: 166px;
                height: 38px;
                align-self: end;
                background-image: url('../../images/Careers/wantedly.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                transition: all 160ms ease-out;
    
                &:hover {
                  background-image: url('../../images/Careers/wantedly_hover.png');
                }
              }
            }
          }
        }
      }

      .mobile-gallery-container {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding-top: 40px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: white;

        &-gallery {
          display: grid;
          gap: 27px;
          padding: 0 10px;

          img {
            width: 100%;
            aspect-ratio: 330 / 220;
          }
        }
      }

      .mobile-recruiting-philosorhy-container {
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding-top: 40px;
        padding-bottom: 60px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: white;

        p {
          margin: 0;
        }

        &-content {
          display: flex;
          flex-direction: column;
          gap: 60px;

          &-text {
            display: flex;
            flex-direction: column;
            gap: 50px;

            &-title {
              font-size: 26px;
              font-weight: bold;
              line-height: 120%;
            }

            &-text {
              font-size: 12px;
              line-height: 230%;
              letter-spacing: 2%;
            }
          }

          &-sub-text {
            font-size: 14px;
            line-height: 230%;
            letter-spacing: 2%;
            text-align: center;
          }
        }
      }

      .mobile-qa-container {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 40px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: white;
      }
    }

    .careers-contactUs {
      display: flex;
      flex-direction: column;
      gap: 18px;
      margin: 0;
      text-align: center;
      justify-content: center;

      a {
        width: fit-content;
        align-self: center;
      }

      button {
        width: 250px;
        height: 78px;
      }
    }

    .accordion-block {
      &-title {
        padding: 10px 0;
        font-size: 16px;
        font-weight: 500;
      }

      .accordion-item {
        margin: 0;
        font-size: 14px;

        &-title {
          padding: 10px 0;
        }

        &-content {
          padding: 0 32px;
        }

        .accordion-item-content-text {
          margin: 0;
          font-weight: 300;
        }
      }
    }
  }
}


// ************************************************************************************************************************
// 舊的樣式，其他頁面也會用到
// ************************************************************************************************************************
.swiper-backface-hidden .swiper-slide {
  transform: initial;
}

.swiper-wrapper {
  height: 380px;
}

.careers-env img {
  object-fit: cover;
  height: 100%;
}

.careers-zone {
  margin: 100px auto 100px auto;
  .title-with-bg-wrapper{
    margin-bottom: 60px;
  }
}

.careers-text {
  display: flex;

  h1 {
    font-weight: 700 !important;
  }
}

.careers .whiteTitle-content {
  h1 {
    margin-bottom: 20px;
    line-height: 120%;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    text-align: center;
    color: #000000;
    div{
      display: inline-block;
    }
  }

  p{
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
    margin-bottom: 80px;
    div{
      display: inline-block;
    }
  }

  img {
    width: 254px !important;
    padding-top: 180px;
    padding-bottom: 60px;
  }
}

.careers-cultureTitleWrapper {
  text-align: left;
}

.careers video, .company video {
  height: var(--page-video-height);
  object-fit: cover;
}

.career-videoBox{
  height: var(--page-video-height);
}

.careers-videoDivider {
  border-left: 1px solid white;
  display: inline-block;
  height: 70px;
  margin: 0 56px;
}

.careers-videoTitle {
  align-items: center;
  display: flex;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.company-videoTitle {
  align-items: center;
  display: flex;
  left: 50%;
  position: absolute;
  top: 60%;
  transform: translate(-50%, -50%);
}

.careers-titleWrapper {
  align-self: center;
  color: white;
  display: grid;
  text-align: center;
  white-space: nowrap;
}
.careers-titleWrapper p {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  margin-right: 10px;
}

.careers-videoTable {
  align-items: center;
  border: 1px solid white;
  display: flex;
  height: 32px;
  white-space: nowrap;
}

.careers-videoTableRight {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 0px;
}

.careers-videoTableLeft {
  align-items: center;
  display: flex;
  height: 100%;
}

.careers-videoTableRight {
  display: inline-block;
  color: white;
  padding: 9px 6px;
}

.careers h1 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 38px;
  font-weight: 700;
}

.careers .whiteTitle-subtitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 180%;
  color: #000000;
}

.careers-contactUs {
  display: flex;
  gap: 60px;
  margin: 60px 0 0px 0;
  text-align: center;
  justify-content: center;
}
.careers-recruiting+.careers-contactUs {
  margin: 40px 0 100px 0;
  text-align: center;
}

.careers-mission {
  color: black;
  padding: 142px 0 142px 0;
  .title-with-bg-wrapper{
    margin-bottom: 34px;
  }
}

.careers-missionHeadline {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 0;
}
.careers-missionHeadline p {
  margin-bottom: 0;
}

.careers-values {
  // background: url('../../images/values_bg.png');
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 110px 120px 110px;
  .title-with-bg-wrapper{
    margin-bottom: 60px;
  }
}

.careers-valueCards {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
}

.careers-env {
  display: grid;
  grid-template-columns: 54fr 5fr 50fr;
}
.careers-env img {
  border-radius: 10px;
  width: 100%;
}

.careers-cultures {
  margin-bottom: 125px;
  margin-top: 15px;
  position: relative;
  .careers-cultureSwiper .swiper-wrapper{
    height: auto;
  }
  .careers-cultures-content{
    margin-bottom: 30px;
    img{
      margin-left: 40px;
    }
  }
}

.careers-culture-feature{
  h3{
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 120%;
    color: #000000;
    margin-bottom: 24px;
    text-align: center;
    padding: 4px 10px;
    transition: all .2s;
    span{
      display: inline-block;
      padding: 2px 5px;
      position: relative;
      z-index: 2;
      color: #000;
      i{
        position: relative;
        font-style: normal;
        z-index: 2;
        color: #000;
        transition: all 1s;
      }
      &:after{
        content: '';
        z-index: 1;
        position: absolute;
        width: 1px;
        transition: all 1s;
        transform-origin: center left;
        height: 100%;
        top: 0px;
        left: 0px;
        background: rgba(32, 67, 119, 0);
      }
    }

  }
  p{
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    color: #000000;
  }
  &:hover{
    cursor: default;
    h3{
      font-weight: 900;
      font-size: 24px;
      line-height: 120%;
      i{
        color: #FFFFFF;
      }
      span{
        color: #FFFFFF;
        &:after{
          width: 100%;
          background: rgba(32, 67, 119, 1);
        }
      }
    }
  }
}
@media(max-width: 576px){
  .careers-culture-feature{
    margin-bottom: 15px;
    h3{
      font-weight: 900 !important;
      font-size: 24px;
      line-height: 120%;
      color: rgb(32, 67, 119);
      margin-bottom: 10px;
      &.active{
        font-weight: 600;
      }
      i{
        color: #FFFFFF !important;
      }
      span{
        color: #FFFFFF;
        &:after{
          width: 100%;
          background: rgba(32, 67, 119, 1);
        }
      }
    }
  }
}

.culture-imgBox{
  padding-top: 86%;
  position: relative;
  @media(max-width: 576px){
    padding-top: 100%;
    margin-bottom: 10px;
  }
  img{
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    z-index: 3;
    width: 86%;
    max-width: 86%;
    transition: opacity 1s;
    &.active{
      transition: opacity 1s;
      opacity: 1;
      z-index: 3;
    }
    &.culture-img-default{
      opacity: 1;
      z-index: 2;
    }
  }
}

.careers-commBg {
  background: url('../../images/communication_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.careers-comm {
  color: white;
  display: grid;
  gap: 5%;
  grid-template-columns: 1fr 1fr;
  height: 376px;
  justify-content: space-between;
  object-fit: cover;
  padding: 110px;
}

.careers-commContent {
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.03em;
  margin-bottom: 30px;
}

.careers-content h1 {
  font-weight: 700;
}

.careers-content h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
  margin-bottom: 10px;
}

.careers-logos {
  align-items: center;
  display: flex;
  gap: 22px;
  justify-content: space-around;
}

#comm-notion-logo {
  height: 28px;
}

.careers-recruiting {
  display: grid;
  gap: 65px;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  padding-top: 100px;
  margin-bottom: 40px;
}

.careers-recruiting .title-with-bg, .careers-recruiting .subtitle-with-bg {
  text-align: left;
  padding-left: 15px;
}

.careers-recruiting > .careers-content h4 {
  margin-bottom: 10px;
  margin-top: 25px;
}

.careers-carousel {
  align-items: center;
  display: flex;
  max-width: 588px;
  width: 35vw;
}

.careers-carouselSwiper {
  position: relative;
}
.careers-cultureSwiper{
  .swiper-wrapper{
    height: auto;
  }
}

.careers-recruitingTitleWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 150px;
}
.careers-titleWrapper h1 {
  font-size: 42px;
  margin-bottom: 15px;
}

.careers-recruitingStepsTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.careers-recruitingNote {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 180%;
  letter-spacing: 0.02em;
  margin-top: 4px;
}

.careers-valueTitle {
  display: grid;
  justify-content: start;
  margin-left: 110px;
  padding-top: 37px;
}

.careers-step{
  i{
    font-style: normal;
  }
}

@media only screen and (max-width: 1024px) {
  .careers-valueCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .careers-whiteCultureTitleWrapper {
    display: none;
  }

  .careers-cultureTitleWrapper {
    display: block;
    margin-left: 20px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .careers-recruiting {
    grid-template-columns: 1fr;
    height: auto;
    padding: 30px 20px;
    position: relative;
    gap: 20px;
    margin-bottom: 15px;
  }

  .careers-recruiting .careers-content {
    margin: 25% auto 0;
    width: 90%;
  }

  .careers-recruitingWrapper {
    display: flex;
    justify-content: center;
  }

  .careers-recruitingTitleWrapper {
    position: absolute;
    top: 30px;
    right: auto;
  }

  .careers-comm {
    flex-direction: column;
    display: flex;
    gap: 20px;
    height: auto;
    padding: 20px 20px 55px;
  }
  .careers-comm > .container {
    padding: 0;
  }

  .careers-cultureTitleWrapper {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 865px){
  .careers-content h4 {
    line-height: 120%;
  }
}

@media only screen and (max-width: 768px) {
  .careers-contactUs {
    gap: 8px;
  }
}

@media only screen and (max-width: 576px) {
  .swiper-wrapper {
    height: 240px;
  }
  .careers-cultureSwiper .swiper-wrapper{
    height: 280px;
    .cultureSlide  h4{
      font-size: 32px;
    }
    .cultureSlide-title{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .careers-cultureTitleWrapper {
    //display: none;
  }
  .careers .title-with-bg-wrapper {
    display: inline-grid !important;
    text-align: center;
    margin: 0 auto 0px auto;
    padding: 16px;
  }

  .careers-recruiting{
    padding-bottom: 0px;

    .title-with-bg {
      text-align: center;
    }
  }

  .culture-imgBox img{
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }

  .careers-cultures {
    .careers-cultures-content{
      img{
        margin-left: 0px;
      }
    }
  }

  .careers-recruitingNote {
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
  }

  .careers .whiteTitle-content h1 {
    font-size: 32px;
    display: block;
  }

  .careers-values {
    padding: 30px 0 40px 0;
  }

  .careers-cultures {
    .title-with-bg-wrapper {
      display: none;
    }
  }
  

  .careers-comm {
    background: url('../../images/comm_mobile_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .careers-carousel {
    margin: auto;
    width: 100%;
  }

  .careers-valueCards {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
  }

  .careers-videoTitle, .company-videoTitle {
    flex-direction: column;
    gap: 35px;
    text-align: center;
    width: 100%;
  }

  .careers-titleWrapper p {
    font-size: 19px;
    line-height: 10px;
    margin-right: 0;
    margin-bottom: 0px;
  }

  .careers-videoTable {
    margin-right: 0;
  }

  .careers-titleWrapper {
    margin: 0;
  }
  .careers-titleWrapper h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 26px;
    margin-bottom: 15px;
  }

  .careers-logos {
    gap: 12px;
  }

  .careers-videoDivider {
    display: none;
  }

  .careers-mission {
    padding: 35px 20px;
  }
  
  .careers-missionHeadline {
    padding: 30px 11.5px;
  }


  .careers-valueTitle {
    margin-left: 20px;
  }

  .careers-env {
    position: relative;
  }

  .careers-env {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    padding: 10px 0 30px 0;
    gap: 20px;
  }

  .careers-envContent {
    margin-top: 0;
  }

  .second-row {
    justify-content: center;
  }

  .careers-cultures {
    margin-bottom: 60px;
  }

  .careers-recruiting .careers-content {
    margin: 60% auto 0;
    width: 90%;
  }

  .careers-envContent div {
    margin-bottom: 20px;
  }

  .careers-content h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 0;
  }

  .careers h1 {
    text-align: center;
    font-size: 22px;
    line-height: 26px;
  }

  .careers-zone {
    margin: 30px auto 0px auto;
  }

  .careers .title-with-bg-wrapper {
    text-align: right;
    margin: 0 auto 0px auto;
    padding: 12px 20px;
  }

  .careers .title-wrapper {
    text-align: center;
    margin-bottom: 30px;
  }

  .careers .careers-envTitleWrapper {
    margin-bottom: 10px;
  }

  .careers .title-with-bg {
    padding: 6px 0 6px 0;
    font-size: 38px;
  }

  .careers-text {
    display: block;
    margin-bottom: 15px;

    h1 {
      font-size: 32px !important;
    }
  }
  .careers .whiteTitle-content h1 div{
    display: block;
  }
  .careers .whiteTitle-content p{
    font-size: 18px;
    div{
      display: block;
    }
  }
  .careers .whiteTitle-content img{
    padding-top: 98px;
  }
  .careers .whiteTitle-content p{
    margin-bottom: 30px;
  }

  .careers-contactUs {
    display: grid;
    gap: 14px;
  }

  .careers-recruiting + .careers-contactUs{
    margin: 40px 0 60px 0;
  }

  .careers-culture-feature{
    h3{
      color: rgb(32, 67, 119);
      font-weight: 600;
    }
    &.active{
      font-weight: 600;
    }
  }

}

@media only screen and (max-width: 420px) {
  
  .careers-carousel {
    margin-top: 25px;
  }

  #comm-zoom-logo, #comm-team-viewer-logo {
    height: 12px;
  }

  #comm-notion-logo {
    height: 22px;
  }

  #comm-slack-logo {
    height: 15px;
  }

  .careers-missionHeadline {
    font-size: 20px;
  }

  .careers-recruiting > .careers-content h4 {
    margin-top: 30px;
  }

  .careers video, .company video {
    margin-top: 0;
  }

  .careers-recruitingTitleWrapper {
    padding-right: 0;
  }

  .careers-cultures .title-with-white-bg, .careers-cultures .subtitle-with-white-bg {
    display: none;
  }
}
